import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './../node_modules/bulma/css/bulma.css';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

import './registerServiceWorker'

// Global functions
Vue.mixin({
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently()
    },
  },
})

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
