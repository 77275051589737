import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../components/Home.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/matches',
    name: 'Matches',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../views/Matches.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/matches/:id/meetups',
    name: 'MatchMeetups',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/ViewMatchMeetups.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/matches/:id/add_request',
    name: 'MatchRequest',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/AddMatchRequest.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/matches/:id/spending',
    name: 'MatchSpending',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/ViewMatchSpending.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/matches/:id/add_spending',
    name: 'AddMatchSpending',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/AddMatchSpending.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/policy_documents',
    name: 'PolicyDocuments',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../views/PolicyDocuments.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/personal_details',
    name: 'PersonalDetails',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/PersonalDetails.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/contact_details',
    name: 'ContactDetails',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/ContactDetails.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/access_ni',
    name: 'AccessNi',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/AccessNi.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/volunteer_progress',
    name: 'VolunteerProgress',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/VolunteerProgress.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/outcomes/:id',
    name: 'ViewOutcome',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/ViewOutcome.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/add_outcome',
    name: 'AddOutcome',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/AddOutcome.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/Help.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/personal_cv',
    name: 'PersonalCV',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/PersonalCV.vue'),
     beforeEnter: authGuard
  },
  {
    path: '/agreement',
    name: 'Agreement',
    // route level code-splitting
     component: () => import(/* webpackChunkName: "matches" */ '../components/Agreement.vue'),
     beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
